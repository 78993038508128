"use client";

import * as Collapsible from "@radix-ui/react-collapsible";
import clsx from "clsx";
import Hyperlink from "components/Hyperlink";
import typography from "components/typography";
import { assetFragment, menuItemFragment } from "contentful/fragments";
import { FragmentType, getFragmentData } from "contentful/gql";
import ArrowSmDown_16px from "icons/ArrowSmDown_16px";
import Bag_24px from "icons/Bag_24px";
import Blog_24px from "icons/Blog_24px";
import Box_24px from "icons/Box_24px";
import Components_24px from "icons/Components_24px";
import Database_24px from "icons/Database_24px";
import GitMerge_24px from "icons/GitMerge_24px";
import GithubLogo_32px from "icons/GithubLogo_32px";
import Handshake_24px from "icons/Handshake_24px";
import Layout_24px from "icons/Layout_24px";
import MenuToClose_32px from "icons/MenuToClose_32px";
import Presentation_24px from "icons/Presentation_24px";
import VideoPlaylist_24px from "icons/VideoPlaylist_24px";
import { useState } from "react";
import Icon from "components/Icon";

export default function MobileMenu({
  githubStars,
  menuItems,
}: {
  githubStars: number;
  menuItems: (FragmentType<typeof menuItemFragment> | null)[] | undefined;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Collapsible.Root className="CollapsibleRoot" open={open} onOpenChange={setOpen}>
      <Collapsible.Trigger asChild>
        <button className="h-[36px] w-[36px] p-[5px]">
          <MenuToClose_32px open={open} />
        </button>
      </Collapsible.Trigger>

      <Collapsible.Content className="absolute inset-0 top-full z-50 h-screen w-screen bg-primary-neutral-50">
        <nav className={clsx(typography.label, "container bg-primary-neutral-50")}>
          <ul className="space-y-sm">
            {menuItems?.map((itemFragment) => {
              const item = getFragmentData(menuItemFragment, itemFragment);
              if (
                (item?.menuCollection?.items && item.menuCollection.items.length > 0) ||
                (item?.menuIiCollection?.items && item.menuIiCollection.items.length > 0)
              ) {
                return (
                  <li key={item.sys.id}>
                    <details className="group/menu">
                      <summary className="flex items-center justify-between pr-3xs">
                        <div className="text-xl font-medium">{item?.heading}</div>
                        <span className="h-sm w-sm transition-transform duration-150 group-open/menu:-rotate-180">
                          <ArrowSmDown_16px />
                        </span>
                      </summary>
                      <div className="rounded bg-primary-neutral-50">
                        <ul className="mt-xs grid gap-xs">
                          {(item?.menuCollection?.items || item?.menuIiCollection?.items)?.map((item) => {
                            const icon = getFragmentData(assetFragment, item?.icon);
                            return (
                              item && (
                                <Collapsible.Trigger key={item.sys.id} asChild>
                                  <Hyperlink
                                    href={item.link ?? ""}
                                    className="group/menuItem flex items-center text-base font-normal transition-colors hover:font-medium"
                                  >
                                    <div
                                      className={clsx(
                                        "relative mr-xs flex h-[36px] w-[36px] items-center p-[10px] text-primary-light-500 transition-colors duration-300",
                                        "group-hover/menuItem:text-primary-neutral-50",
                                      )}
                                    >
                                      <div className={"relative mr-xs h-8 w-8 flex-shrink-0 p-2"}>
                                        <span className="absolute left-0 top-0 z-0 block h-full w-full rounded-full bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 transition-opacity duration-300 group-hover:opacity-0"></span>
                                        <span className="absolute left-0 top-0 z-0 block h-full w-full rounded-full bg-gradient-to-br from-primary-light-500 to-primary-light-650 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></span>
                                        <span className="relative z-10 block h-full w-full text-primary-light-500 transition-colors duration-300 group-hover:text-primary-neutral-50">
                                          <Icon strokeWidth={1} url={icon?.url ?? ""} />
                                        </span>
                                      </div>
                                    </div>
                                    <span className="text-sm font-medium text-primary-dark-500 transition-colors group-hover:text-primary-dark-900">
                                      {item?.heading}
                                    </span>{" "}
                                  </Hyperlink>
                                </Collapsible.Trigger>
                              )
                            );
                          })}

                          {/* New section for menuIiCollection */}
                          {item?.menuIiCollection?.items?.map((item) => {
                            const icon = getFragmentData(assetFragment, item?.icon);
                            return (
                              item && (
                                <Collapsible.Trigger key={item.sys.id} asChild>
                                  <Hyperlink
                                    href={item.link ?? ""}
                                    className="group/menuItem flex items-center text-base font-normal transition-colors hover:font-medium"
                                  >
                                    <div
                                      className={clsx(
                                        "relative mr-xs flex h-[36px] w-[36px] items-center p-[10px] text-primary-light-500 transition-colors duration-300",
                                        "group-hover/menuItem:text-primary-neutral-50",
                                      )}
                                    >
                                      <div className={"relative mr-xs h-8 w-8 flex-shrink-0 p-2"}>
                                        <span className="absolute left-0 top-0 z-0 block h-full w-full rounded-full bg-gradient-to-br from-primary-light-500/10 to-primary-light-650/10 transition-opacity duration-300 group-hover:opacity-0"></span>
                                        <span className="absolute left-0 top-0 z-0 block h-full w-full rounded-full bg-gradient-to-br from-primary-light-500 to-primary-light-650 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></span>
                                        <span className="relative z-10 block h-full w-full text-primary-light-500 transition-colors duration-300 group-hover:text-primary-neutral-50">
                                          <Icon strokeWidth={1} url={icon?.url ?? ""} />
                                        </span>
                                      </div>
                                    </div>
                                    <span className="text-sm font-medium text-primary-dark-500 transition-colors group-hover:text-primary-dark-900">
                                      {item?.heading}
                                    </span>{" "}
                                  </Hyperlink>
                                </Collapsible.Trigger>
                              )
                            );
                          })}
                        </ul>
                      </div>
                    </details>
                  </li>
                );
              } else {
                return (
                  <li key={item?.sys.id}>
                    <Collapsible.Trigger asChild>
                      <Hyperlink href={item?.link ?? ""} className="text-xl font-medium">
                        {item?.heading}
                      </Hyperlink>
                    </Collapsible.Trigger>
                  </li>
                );
              }
            })}
          </ul>
        </nav>
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
