"use client";

import CtaLink from "components/CtaLink";
import CtaModal from "components/CtaLink/ModalCTA";
import { CtaFragment } from "contentful/gql/graphql";
import { useScrollInfo, ScrollInfoProvider } from "@faceless-ui/scroll-info";
import { useState, useEffect } from "react";
import { usePathname } from "next/navigation";

export default function HeaderCtas({ ctas }: { ctas: (CtaFragment | null | undefined)[] | undefined }) {
  return (
    <ScrollInfoProvider>
      <HeaderCtasInner ctas={ctas} />
    </ScrollInfoProvider>
  );
}

function HeaderCtasInner({ ctas }: { ctas: (CtaFragment | null | undefined)[] | undefined }) {
  const scrollInfo = useScrollInfo();
  const [showBookDemo, setShowBookDemo] = useState(false);
  const pathname = usePathname();
  const showPricingCta = pathname === "/appsmith-get-started";
  const isAIassistantLandingPage = pathname === "/ai";

  useEffect(() => {
    if (scrollInfo.yPercentage > 2) {
      setShowBookDemo(true);
    } else {
      setShowBookDemo(false);
    }
  }, [scrollInfo.yPercentage]);

  const bookDemoCtaOptions = {
    label: "Book a demo",
    link: "/enterprise?lead_source=navbar",
  };

  const landingPageBookDemoCtaOptions = {
    label: "Request a demo",
    link: "#default-form-id",
  };

  const pricingCtaOptions = {
    label: "Pricing",
    link: "#homepage-pricing-cards",
  };

  return (
    <div className="col-span-3 hidden justify-end space-x-sm lg:flex">
      {ctas?.map((callToAction, index) => {
        return (
          callToAction && (
            <CtaLink
              key={callToAction.sys.id}
              {...callToAction}
              {...(index === 0 && showBookDemo ? bookDemoCtaOptions : [])}
              extendClassNames="2xl:text-xs font-medium default-link"
            />
          )
        );
      })}
      {showPricingCta && <CtaLink {...pricingCtaOptions} variant="secondary" size="sm" extendClassNames="w-full" />}

      {isAIassistantLandingPage ? (
        <CtaModal
          label="Request early access"
          modalHeading="Request early access to Appsmith assistants"
          modalDescription="The beta will be available soon. To join the waitlist, please register, and schedule a quick discussion with our product team."
          variant="primary"
          size="small"
          modalForm={{
            formId: "ai-landing-page",
            formLabel: "AI assistants",
            successMessage: "Thank you for your interest! We'll be in touch soon.",
            submitText: "Request early access",
            chilipiper: true,
          }}
          extendClassNames="text-3xl font-bold"
        />
      ) : (
        <CtaLink
          {...landingPageBookDemoCtaOptions}
          variant="primary"
          size="sm"
          extendClassNames="2xl:text-xs font-medium landing-page-link hidden"
        />
      )}
    </div>
  );
}
