"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { analytics } from "lib/segment";
import { create } from "zustand";
import { addVisitedPageToLocalStorage } from "utils/analytics";
import { ViewedExperiments } from "app/api/get-active-experiments-on-page/route";

type ExperimentsViewedState = {
  viewedExperiments: ViewedExperiments;
  setViewedExperiments: (viewedExperimentsOnPage: ViewedExperiments) => void;
};

export const useExperimentsStore = create<ExperimentsViewedState>((set) => ({
  viewedExperiments: {},
  setViewedExperiments: (viewedExperimentsOnPage: ViewedExperiments) =>
    set((state) => ({ viewedExperiments: { ...state.viewedExperiments, ...viewedExperimentsOnPage } })),
}));

export function NavigationEvents() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { setViewedExperiments } = useExperimentsStore();

  useEffect(() => {
    analytics?.page();
    // collect all visited pages
    const visitedPages = addVisitedPageToLocalStorage(pathname);

    analytics?.identify({
      visited_pages: visitedPages,
    });

    fetch("/api/get-active-experiments-on-page?pathname=" + encodeURIComponent(pathname)).then((res) => {
      if (res.status === 200) {
        return res.json().then((data) => {
          setViewedExperiments(data);
          for (const [experimentId, variant] of Object.entries(data)) {
            analytics?.track("Experiment Viewed", {
              experimentId,
              variant,
            });
            analytics?.identify({ ["exp_" + experimentId]: variant });
          }
        });
      }
    });
  }, [pathname, searchParams, setViewedExperiments]);

  return null;
}
